.document-editor__document__toc-control {
  width: 25mm;
  flex-shrink: 0;
  padding-left: 16px;
}

.document-editor__document__toc-control button:not([role='menuitem']) {
  top: 16px;
  position: sticky;
}

chakra-scope > button + div {
  z-index: 2 !important;
}
